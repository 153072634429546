.textBannerDivColour{
    width: 100%;
    height: 100%;
}

.textBannerDiv{
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.textBannerTextDiv{
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    max-width: 1440px;
    margin: auto;
    color:#e1eaf0;
}

.textBannerText{
    font-weight: 300;
    font-size: 30px;
}

.textBannerText a{
    color: #e1eaf0;
    text-decoration: none;
}

.textBannerText a:hover{
    color: #779eb8;
    text-decoration: none;
}

.textBannerHeader{
    font-weight: 300;
    text-transform: uppercase;
    font-size: 20px;
}
/* #b4d8f0
#0a4486
#e1eaf0 */