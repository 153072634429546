.footer{
    width: 100%;
    background-color: #b4d8f0;
    /* background-image:linear-gradient(180deg, rgba(180,216,240,1) 0%, rgba(180,216,240,1) 10%, #0a4486 100%); */
    height: 360px;
    color: #0a4486;
    font-weight: 400;
}

.footerContent{
    margin: auto;
    max-width: 1080px;
    padding-top: 32px;
}

.footerContentBlock:nth-child(2){
    text-align: right;
    clear: right;
}

.footerContentBlock{
    display: inline-block;
    width: 50%;
    overflow: hidden;
    vertical-align: top;
}

.footerSplitOptions{
    float: right;
    display: table-cell;
    margin-right: 0;
    margin-left: auto;
    min-width: 170px;
}

.footerSplitOptions ul{
    width: auto;
    margin-block: 0px;
    padding-inline-start: 0px;
    list-style: none;
    margin-right: 0;
    margin-left: auto;
}

.footerSplitOptions ul li{
    padding-bottom: 10px;
}

.footerContentBlock img{
    max-width: 300px;
}

.footer a{
    color: #0a4486;
    text-decoration: none;
}

.footer .footerHeading{
    padding-bottom: 16px;
    
}   

.footerMiddleContent{
    text-align: center;
    vertical-align: bottom;
}

.footerBottom{
    text-align: center;
}

@media (pointer:none), (pointer:coarse) {
    .footer{
        width: 100%;
        background-color: #b4d8f0;
        /* background-image:linear-gradient(180deg, rgba(180,216,240,1) 0%, rgba(180,216,240,1) 10%, #0a4486 100%); */
        height: 360px;
        color: #0a4486;
        font-weight: 400;
    }
    
    .footerContent{
        margin: auto;
        max-width: 1080px;
        padding-top: 32px;
    }
    
    .footerContentBlock:nth-child(2){
        text-align: center;
    }
    
    .footerContentBlock{
        display: block;
        width: auto;
        overflow: hidden;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .footerSplitOptions{
        display: block;
        margin-right: auto;
        margin-left: auto;
        min-width: 170px;
        width: 50%;
    }
    
    .footerSplitOptions ul{
        width: auto;
        margin-block: 0px;
        padding-inline-start: 0px;
        list-style: none;
        margin-right: 0;
        margin-left: auto;
    }
    
    .footerSplitOptions ul li{
        padding-bottom: 10px;
    }
    
    .footerContentBlock img{
        max-width: 300px;
    }
    
    .footer a{
        color: #0a4486;
        text-decoration: none;
    }
    
    .footer .footerHeading{
        padding-bottom: 16px;
        
    }   
    
    .footerMiddleContent{
        text-align: center;
        vertical-align: bottom;
    }
    
    .footerBottom{
        text-align: center;
    }
}