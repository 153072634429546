.linkButton{
    display: inline-block;
    background-color: #0b4281;
    color: #e1eaf0;
    padding:4px;
    text-transform:uppercase;
    border-radius: 5%;
    font-size: 15px;
}

.linkButton:hover{
    background-color: #38608d;
    cursor: pointer;
}