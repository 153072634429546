@media (min-width: 720px) {
    .container{
        width: 100%;
        background-color: #FFFFFF;
        border-bottom-color: #b4d8f0;
        border-bottom-style: solid;
        border-bottom-width: 2px;

        position:fixed;
        top:0;
        z-index: 100;
    }
    
    .navbar{
        display: flex;
        width: 100%;
        height: 72px;
        max-width: 1440px;
        margin:auto;
    }

    .navbar .logoDiv{
        position: relative;
        margin-left: 25px;
        margin-right: 25px;
    }

    .logoDiv img{
        height: 60%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .logoDiv img:hover{
        cursor:pointer;
        
    }

    .navButtons{
        height: 100%;
        width: auto;
    }

    .navOptions{
        height: 100%;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        display : flex;
        align-items : center;
    }

    .navOptions .mobileOnly{
        display: none;
    }

    .navOptions li{
        white-space: nowrap;
        display: table-cell;
        font-size: calc(13px - ( 2 * (1px - ((100vw - 1080px ) / 416))));
        color:#0a4486;
        margin-left: 5%;
        margin-right: 5%;
    }

    .navOptions li a{
        color: #0a4486;
        text-decoration: none;
    }

    .navOptions li:hover{
        cursor:pointer;
        border-bottom-style: solid;
        border-bottom-width: 2px;
    }
    .menuButton{
        display: none;
    }
}

@media (max-width: 719px) {
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        }
    
    .container{
        width: 100%;
        background-color: #FFFFFF;
        border-bottom-color: #0a4486;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        position:fixed;
        top:0;
        z-index: 100;
    }
    
    .navbar{
        display: flex;
        width: 100%;
        height: 72px;
        max-width: 1440px;
        margin:auto;
    }

    .navbar .logoDiv{
        position: relative;
        margin-left:auto;
        margin-right:auto;
    }

    .logoDiv img{
        height: 60%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .menuButton{
        height: 100%;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 75px;
    }
    .navButtons{
        margin-top: 72px;
        display: block;
        float:left;
        position: absolute;
        background-color: #FFFFFF;
        padding-right: 10px;
        height: 100%;
        width: 100%;
        display : none;
        z-index: 100;
    }

    .navOptions{
        height: 100vh;
        vertical-align: middle;
        background-color: #FFFFFF;
        margin: 0;
        padding: 0;
        align-items : center;
        
    }

    .navOptions li{
        white-space: nowrap;
        display: block;
        color:#0a4486;
        margin-top: 42px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 42px;
    }

    .navOptions li:hover{
        cursor:pointer;
    }

    .navOptions li a{
        text-decoration: none;
        color: #0a4486;
    }

    .menuIcon{
        width: 40%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    
}
/* #b4d8f0
#0a4486
#e1eaf0 */