.infoDivBackground{
    width: 100%;
    padding-top: 50px;   
    padding-bottom: calc(20px + 1.5em);
}

.infoDivContainer{
    max-width: 1080px;
    margin: auto;
}

.infoDivContainer a{
    color: #0a4486;
    text-decoration: underline;
}

.infoDivContainer a:hover{
    color: #80a9c2;
    text-decoration: none;
}

.infoDivHeader{
    font-size: 1.5em;
    font-weight: 400;
    padding-bottom: 5%;
    color: #0a4486;
}

.infoDivLeft{
    display: table-cell;
    padding-right: 5%;
    
    padding-bottom: 0px;
    border-right-style: solid;
    border-bottom-style: none;
    border-right-width: 2px;
    border-color: #0a4486;
}

.infoDivRight{
    display: table-cell;
    padding-left: 5%;
}

.infoDivLeft, .infoDivRight{
    font-size: 20px;
    font-weight: 400;
    color: #0a4486;
    vertical-align: top;
}

.infoDivLeft li, .infoDivRight li{
    font-size: 16px;
}
.infoDivSubHeader{
    font-size: 23px;
    font-weight: 400;
    color: #0a4486;
}
/*Mobile*/
@media (pointer:none), (pointer:coarse) {
    .infoDivBackground{
        width: 100%;
        padding-top: 5px;   
        padding-bottom: calc(20px + 1.5em);
    }

    .infoDivContainer{
        max-width: 1080px;
        margin: auto;
    }

    .infoDivContainer a{
        color: #0a4486;
        text-decoration: underline;
    }

    .infoDivContainer a:hover{
        color: #80a9c2;
        text-decoration: none;
    }

    .infoDivHeader{
        font-size: 1.5em;
        font-weight: 400;
        padding-bottom: 5%;
        color: #0a4486;
    }

    .infoDivLeft{
        display: block;
        border-right-style: none;
        border-bottom-style: solid;
        padding-bottom: 20px;
        border-right-width: 2px;
        border-color: #0a4486;
    }

    .infoDivRight{
        display:block;
        padding-left: 5%;
    }

    .infoDivLeft, .infoDivRight{
        font-size: 20px;
        font-weight: 400;
        color: #0a4486;
        padding-left: 20px;
        padding-right: 20px;
        vertical-align: top;
    }

    .infoDivLeft li, .infoDivRight li{
        font-size: 16px;
    }
    .infoDivSubHeader{
        font-size: 23px;
        font-weight: 400;
    }
        
}