.content{
    margin-top: 72px;
}

.info-button {
    border: none;
    padding: 7px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
}

h2 {
    margin-top: 5%;
    margin-bottom: 2%;
}

body{
    background-color: #b4d8f0;
}

* {
    font-family: 'Montserrat';
}

p, ul {
    font-size: 16px;
}

::-webkit-scrollbar {
    width: 15px;
}
  
 ::-webkit-scrollbar-track {
    background: #e1eaf0; 
}
   
::-webkit-scrollbar-thumb {
    background: #0a4486; 
}
  
::-webkit-scrollbar-thumb:hover {
    background: #b4d8f0; 
}

.videoDisplay{
    width: 423px;
    height: 250px;
}
/* #b4d8f0
#0a4486F
#e1eaf0 */

@media (pointer:none), (pointer:coarse) {
    .videoDisplay{
        width: 100%;
        height: auto;
    }
}