/*Desktop*/
@media (pointer:fine) {

    .flex-container {
        width: 100%;
        margin:auto;
        max-width: 1080px;
        padding-top: 50px;
        font-weight: 400;
        display: flex;
    }
    
    .flex-child {
        flex: 1;
    }  
    
    .flex-child:right {
        padding-right: 10%;
        display: table-cell;
    } 

    .flex-child:left {
        padding-left: 10%;
        display: table-cell;
    } 

    .info-h2 {
        margin-top: 5%;
        padding-bottom: 5%;
        font-weight: 400;
    }

    .info-spacer {
        padding-bottom: 40px;
    }
    
    .info-p{
        font-size: 20px;
    }

    .info-p, .info-h2 {
        /* color: #B4D8F0; */
        margin-left: 0%;
        margin-right: 50%;
        margin:auto;
    }

    .imageDiv{
        width: 300px;
        height: 300px;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 5%;
        overflow: hidden;
        background-size:cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .imageDivSubHeader{
        font-weight: 400;
        padding-bottom: 15px;
        font-size: 23px;
    }

    .imageDivExtraHHTML{
        margin:auto;
        max-width: 1080px;
        padding-bottom: 50px;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: 400;
        font-size: 20px;
    }
}

/*Mobile*/
@media (pointer:none), (pointer:coarse) {
    .flex-container {
        width: 100%;
        max-width: 80%;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 400;
        display: block;
        position: relative;
    }
    
    .flex-child {
        flex: 1;
    }  
    
    .flex-child:right {
        padding-right: 10%;
        width: 100%;
        display: block;
        position: absolute;
    } 

    .flex-child:left {
        padding-left: 10%;
        display: none;
        position: absolute;
    } 

    .info-h2 {
        margin-top: 5%;
        padding-bottom: 5%;
        font-weight: 400;
    }

    .info-spacer {
        padding-bottom: 40px;
    }
    
    .info-p{
        font-size: 20px;
    }

    .info-p, .info-h2 {
        /* color: #B4D8F0; */
        margin-left: 0%;
        margin-right: 50%;
        margin:auto;
        position: relative;
        z-index: 10;
    }

    .imageDiv{
        display: none;
        position:absolute;
        width: 0%;
        height: 0;
        margin-top: 5%;
        margin-bottom: 5%;
        overflow: hidden;
        background-size:cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
    }

    .imageDivSubHeader{
        font-weight: 400;
        padding-bottom: 15px;
        font-size: 23px;
    }

    .imageDivExtraHHTML{
        margin:auto;
        max-width: 1080px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 400;
        font-size: 20px;
    }
}