.headerBlockBackgroundImage{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.headerBlockBackgroundColour{
    width: 100%;
}

.headerBlock{
    width: 100%;
    margin:auto;
    max-width: 1080px;
    padding-bottom: 40px;
}

.headerText{
    padding-top: 32px;
}

.headerText .header{
    font-size: 1.8em;
    font-weight: 300;
}

.headerText .subHeader{
    padding-top: 30px;
    font-size: 2.3em;
    font-weight: 400;
}


.headerImage{
    display: table-cell;
    padding-right: 40px;
}

.headerImage img{
    padding-top: 50px;
    max-width: 420px;
    height: 300px;
    vertical-align: top;
}


.textBlock{
    padding-right: 50px;
    display: table-cell;
}
.text{
    max-width: 1080px;
    margin: auto;
}

.text p{
    font-size: 20px;
    font-weight: 200;
}

.text a{
    text-decoration: underline;
    color: #e1eaf0;
}

.mainText {
    max-width: 540px;
}

.text .headerBlockMainHeading{
    font-weight: 300;
}
/* #b4d8f0
#0a4486
#e1eaf0 */

@media (pointer:none), (pointer:coarse) {
    .headerBlockBackgroundImage{
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .headerBlockBackgroundColour{
        width: 100%;
    }
    
    .headerBlock{
        width: 100%;
        margin:auto;
        max-width: 1080px;
        padding-bottom: 40px;
    }
    
    .headerText{
        padding-top: 32px;
    }
    
    .headerText .header{
        font-size: 1.8em;
        font-weight: 300;
    }
    
    .headerText .subHeader{
        padding-top: 30px;
        font-size: 2.3em;
        font-weight: 400;
    }
    
    
    .headerImage{
        display: table-cell;
        padding-right: 40px;
    }
    
    .headerImage img{
        padding-top: 50px;
        max-width: 420px;
        height: 300px;
        vertical-align: top;
    }
    
    
    .textBlock{
        padding-right: 20px;
        padding-left: 20px;
        display: table-cell;
    }
    .text{
        max-width: 1080px;
        margin: auto;
    }
    
    .text p{
        font-size: 20px;
        font-weight: 200;
    }
    
    .text a{
        text-decoration: underline;
        color: #e1eaf0;
    }
    
    .mainText {
        max-width: 540px;
    }
    
    .text .headerBlockMainHeading{
        font-weight: 300;
    }
}