.swiperCard{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.swiperCardTextBlock{
    padding-left: 50%;
    padding: 20px;
    background: rgb(0,0,0);
    background: linear-gradient(230deg, rgba(0,0,0,0) 10%, rgba(15, 43, 75) 85%);
    height: 100%;
}

.swiperCardTextBlock .swiperCardHeader{
    margin-left: 20%;
    font-size: 2em;
    padding-top: 6%;
    font-weight: 500;
    color: #b4d8f0;
}

.swiperCardTextBlock .swiperCardText{
    margin-left: 20%;
    font-size: 3em;
    font-weight: 300;
    color: #e1eaf0;
    z-index: 900000;
}

/* #b4d8f0
#0a4486
#e1eaf0 */