.divBannerDivColour{
    width: 100%;
    height: 100%;
}

.divBannerDiv{
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.divBannerTextDiv{
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    max-width: 1080px;
    margin: auto;
}

.divBannerText{
    font-weight: 300;
    font-family: "Montserrat";
    font-size: 30px;
}

.divBannerDiv a{
    color: #0a4486;
    text-decoration: underline;
}

.divBannerDiv a:hover{
    color: #80a9c2;
    text-decoration: none;
}

/* #b4d8f0
#0a4486
#e1eaf0 */